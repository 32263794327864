
import {defineComponent} from 'vue'
import RelativeTime from "@/components/base/datetime/RelativeTime.vue";

export default defineComponent({
  name: "MailEntitiesItem",
  components: {RelativeTime},
  props: {
    box: {},
    mail: {type: Object, required: true},
  },
  emits: ['show'],
  methods: {
    onShow(item) {
      this.$emit("show", item);
    }
  }
})
