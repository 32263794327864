
import {defineComponent, ref, watch} from 'vue'
import MailService from "@/core/services/MailService";
import Pagination from "@/components/base/paging/Pagination.vue";
import TagEntities from "@/views/tag/TagEntities.vue";
import MailEntitiesItem from "@/views/mail/MailEntitiesItem.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "MailEntities",
  components: {DateTimeFormat, MailEntitiesItem, TagEntities, Pagination},
  props: {
    query: {}
  },
  setup(props) {
    const show = ref(false);
    const entity = ref<any>({});
    const data = ref<any>({total: 0, data: []});
    const filter = ref<any>({
      paginationPage: 1,
      paginationSize: 20,
      key: {s: 'sort:received:desc'},
    })

    filter.value.key = Object.assign(filter.value.key, props.query);
    const loading = ref(true);

    const loadData = () => {
      loading.value = true
      MailService.findAll(filter.value).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }

    const handleUpdatePage = (event) => {
      filter.value.paginationPage = event;
      loadData()
    };

    watch(() => props.query, cb => {
      filter.value.key = Object.assign(filter.value.key, cb);
      filter.value.paginationPage = 1;
      loadData();
    })

    loadData()
    return {
      handleUpdatePage,
      data,
      filter,
      loading,
      entity,
      show,
    }
  },
  methods: {
    onShow(item) {
      this.show = true;
      this.entity = item
    }
  }
})
